import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c72fd212"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = { class: "chevron" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BottomSheet = _resolveComponent("BottomSheet")!
  const _component_ChevronUp = _resolveComponent("ChevronUp")!
  const _component_ChevronDown = _resolveComponent("ChevronDown")!

  return (_openBlock(), _createElementBlock("div", {
    class: "select-box",
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showBS = !_ctx.showBS))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        textContent: _toDisplayString(_ctx.display)
      }, null, 8, _hoisted_2)
    ]),
    _createVNode(_component_BottomSheet, {
      bottomTitle: _ctx.placeholder,
      options: _ctx.options,
      onSelect: _ctx.select,
      modelValue: _ctx.showBS,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showBS) = $event))
    }, null, 8, ["bottomTitle", "options", "onSelect", "modelValue"]),
    _createElementVNode("span", _hoisted_3, [
      (_ctx.showBS)
        ? (_openBlock(), _createBlock(_component_ChevronUp, {
            key: 0,
            size: 18
          }))
        : (_openBlock(), _createBlock(_component_ChevronDown, {
            key: 1,
            size: 18
          }))
    ])
  ]))
}