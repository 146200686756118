import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fc896ca0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "category" }
const _hoisted_3 = { class: "tools" }
const _hoisted_4 = {
  key: 0,
  class: "unread"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TalkroomSelectBox = _resolveComponent("TalkroomSelectBox")!
  const _component_MagnifyIcon = _resolveComponent("MagnifyIcon")!
  const _component_BellOutlineIcon = _resolveComponent("BellOutlineIcon")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TalkroomSelectBox, {
        options: _ctx.options,
        modelValue: _ctx.modelValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.option) = $event)),
        placeholder: "수다방 카테고리를 선택해 주세요."
      }, null, 8, ["options", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('goSearch')))
      }, [
        _createVNode(_component_MagnifyIcon, { size: 25 })
      ]),
      _createElementVNode("button", {
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.clickAlert && _ctx.clickAlert(...args)))
      }, [
        _createVNode(_component_BellOutlineIcon, { size: 25 }),
        (_ctx.isLogin && _ctx.unreadCount > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("span", null, _toDisplayString(_ctx.unreadCount), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}