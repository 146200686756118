
import { defineComponent } from "vue";
import TalkroomSelectBox from "@/components/talkroom/SelectBox.vue";
import BellOutlineIcon from "icons/BellOutline.vue";
import MagnifyIcon from "icons/Magnify.vue";
import { useStore } from "vuex";
import { categories } from "./constants";

export default defineComponent({
  props: {
    modelValue: String,
  },
  components: { BellOutlineIcon, MagnifyIcon, TalkroomSelectBox },
  emits: ["goSearch", "openAlert", "update:modelValue"],
  data() {
    const store = useStore();
    return {
      store,
      option: "",
      options: this.$_.map(["전체", ...categories], (v) => {
        return { display: v, value: v };
      }),
      unreadCount: 0,
    };
  },
  computed: {
    isLogin: function () {
      return this.store.getters["member/isLogin"];
    },
  },
  watch: {
    option(value) {
      this.$emit("update:modelValue", value);
    },
  },
  methods: {
    clickAlert() {
      this.$pushGAEvent("alert_click");
      this.$emit("openAlert");
    },
  },
  mounted() {
    if (this.isLogin) {
      this.$axios.get("/push/alert/unread/count").then((res) => {
        this.unreadCount = res.data.count;
      });
    }
  },
});
