
import { defineComponent } from "vue";
import { useStore } from "vuex";
import Header from "@/components/talkroom/Header.vue";
import TalkroomList from "@/components/talkroom/List.vue";
import BaseButtonCircle from "@/components/BaseButtonCircle.vue";
import { TalkroomListType } from "@/components/talkroom/talkroom";
import { TalkroomListTypeFromJson } from "@/components/talkroom/utils";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
import LoadingIcon from "icons/Loading.vue";
import PlusIcon from "icons/Plus.vue";

export default defineComponent({
  name: "Talkroom",
  components: {
    Header,
    TalkroomList,
    InfiniteLoading,
    LoadingIcon,
    PlusIcon,
    BaseButtonCircle,
  },
  data() {
    const store = useStore();
    return {
      store,
      page: 1,
      isLoading: false,
      infiniteId: +new Date(),
      talkroomList: [] as TalkroomListType,
    };
  },
  computed: {
    category: {
      get(): string {
        return this.store.getters["talkroom/selectedTabName"];
      },
      set(value: string) {
        this.store.dispatch("talkroom/updateSelectedTabName", value);
      },
    },
  },
  watch: {
    category(value) {
      this.$pushGAEvent("talkroom_category_click", {
        talkroom_category: value,
      });
      this.page = 1;
      this.talkroomList.splice(0, this.talkroomList.length);
      this.infiniteId += 1;
    },
  },
  methods: {
    infiniteHandler($state: any) {
      $state.loading();
      this.reloadTalkrooms()
        .then((res: any) => {
          $state.loaded();
          if (this.talkroomList.length >= res.data.count) {
            $state.complete();
          }
          this.page += 1;
        })
        .catch(() => {
          $state.loaded();
          $state.complete();
        });
    },
    reloadTalkrooms() {
      this.isLoading = true;
      return this.$axios
        .get("/board", {
          params: {
            page: this.page,
            category: this.category == "전체" ? null : this.category,
          },
        })
        .then((res) => {
          const responseData = TalkroomListTypeFromJson(res.data.results);
          if (responseData.length > 0) {
            if (this.page == 1) {
              this.talkroomList = responseData;
            } else {
              this.talkroomList.push(...responseData);
            }
          }
          this.isLoading = false;
          return res;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
  beforeMount() {
    this.$flutter.callHandler("showGnb");
  },
  beforeUnmount() {
    this.$flutter.callHandler("hideGnb");
  },
});
